




































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { RunConcentration } from 'common-modules/src/store/interface/RunConcentration';
import { ExtendedRunConcentration, MasterConcentration } from '@/store/interface/Concentration';

const JwlShowMore = () => import('@/components/jwlShowMore.vue');
const JwlCourse = () => import('@/components/JwlCourse.vue');
const LmsCourseDocument = () => import('@/components/LmsCourseDocument.vue');

@Component({
  components: {
    JwlShowMore,
    JwlCourse,
    LmsCourseDocument,
  },
})
export default class JwlConcentration extends Vue {
  @Prop(Object)
  masterConcentration!: MasterConcentration;

  @Prop(Object)
  runConcentration!: RunConcentration;

  @Prop(Object)
  extendedRunConcentration!: ExtendedRunConcentration;

  @Prop(String)
  unitDetailPathName!: string;

  @Prop(Boolean)
  showToggle!: boolean;

  formattedDate (dateString: string): string {
    const date = new Date(dateString);
    return date.toLocaleDateString();
  }

  get baseConcentration (): MasterConcentration | RunConcentration | ExtendedRunConcentration {
    return this.masterConcentration || this.extendedRunConcentration || this.runConcentration;
  }

  get progress (): number {
    if (this.runConcentration?.startDate && this.runConcentration.endDate) {
      const today = new Date();
      const startDate = new Date(this.runConcentration.startDate);
      const endDate = new Date(this.runConcentration.endDate);
      const progress = (today.getTime() - startDate.getTime()) / (endDate.getTime() - startDate.getTime());
      return Math.max(0, Math.min(progress * 100, 100));
    }
    return 0;
  }

  get progressText (): string {
    return (this.progress / 100).toLocaleString('en', { style: 'percent' });
  }

  get progressStyle (): string {
    return `width: ${this.progress}%;`;
  }
}
