
















import { mapGetters } from 'vuex';
import Vue from 'vue';
import Component from 'vue-class-component';
import Concentrations from 'common-modules/src/store/Concentrations';
import { RunConcentration } from 'common-modules/src/store/interface/RunConcentration';
import CommonIcon from 'common-modules/src/components/CommonIcon.vue';
import { ExtendedRunConcentration } from '@/store/interface/Concentration';
import JwlConcentration from '@/components/JwlConcentration.vue';

@Component({
  components: {
    JwlConcentration,
    CommonIcon,
  },
  computed: mapGetters([
    'baseConcentrations',
    'concentration',
  ]),
})
export default class JwlCourseHome extends Vue {
  baseConcentrations!: Concentrations;
  concentration!: ExtendedRunConcentration | null;

  formattedDate (dateString: string): string {
    const date = new Date(dateString);
    return date.toLocaleDateString();
  }

  get baseConcentration (): RunConcentration | undefined {
    const concentrationCode = this.$route.params.concentration;
    return this.baseConcentrations.dataAsFlatArray().find((baseConcentration) => baseConcentration.code === concentrationCode);
  }

  get progress (): number {
    if (this.baseConcentration?.startDate && this.baseConcentration.endDate) {
      const today = new Date();
      const startDate = new Date(this.baseConcentration.startDate);
      const endDate = new Date(this.baseConcentration.endDate);
      const progress = (today.getTime() - startDate.getTime()) / (endDate.getTime() - startDate.getTime());
      return Math.max(0, Math.min(progress * 100, 100));
    }
    return 0;
  }

  get progressText (): string {
    return (this.progress / 100).toLocaleString('en', { style: 'percent' });
  }

  get progressStyle (): string {
    return `width: ${this.progress}%;`;
  }
}
